import React from "react"
import * as styles from "./contactList.module.scss"

function ContactList({ data }) {
  const ContactList = data.map(contact => {
    return (
      <div className={styles.contactList__item}>
        <p>{contact.title}</p>
        <a href={`mailto:${contact.emailAddress}`}>{contact.emailAddress}</a>
        <span>{contact.phoneNumber}</span>
        <span class="number">{contact.faxNumber}</span>
      </div>
    )
  })

  return (
    <div className={styles.contactList}>
      {ContactList}
    </div>
  )
}

export default ContactList