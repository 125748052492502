import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/layout/seo"
import Hero from "../components/Global/secondLevelHero/secondLevelHero"
import ContactList from "../components/Global/contactList/contactList"
import FullWidthImage from "../components/Global/fullWidthImage/fullWidthImage"
import Footnotes from "../components/Global/footnotes/footnotes"
import Footer from "../components/layout/footer"

const GetInTouch = ({ data }) => {

  const content = data.allContentfulContactPage.nodes[0];

  let heroData = {
    breadcrumbs: content.hero.breadcrumb,
    title: content.hero.title,
    content: content.hero.content,
    backgroundImage: content.hero.backgroundImage,
  }

  let contactData = content.contactList

  let fullWidthBannerData = {
    imageTitle: content.fullWidthBanner.image.title,
    image: getImage(content.fullWidthBanner.image),
  }

  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: [],
  }

  return (
    <Layout>
      <Seo title="Get in touch" />
      <Hero data={heroData}/>
      <ContactList data={contactData} />
      <FullWidthImage data={fullWidthBannerData} />
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulContactPage {
      nodes {
        hero {
          breadcrumb {
            title
            url
          }
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
        }
        contactList {
          title
          emailAddress
          phoneNumber
          faxNumber
        }
        fullWidthBanner {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        jobCodeDate
      }
    }
  }
`

export default GetInTouch
